import { ConnectUI } from './connectUI.js';
import { AuthorizationStatus } from './types.js';
export * from './connectUI.js';
const prodHost = 'https://api.nango.dev';
const debugLogPrefix = 'NANGO DEBUG LOG: ';
export class AuthError extends Error {
  type;
  constructor(message, type) {
    super(message);
    this.type = type;
  }
}
class Nango {
  hostBaseUrl;
  websocketsBaseUrl;
  status;
  publicKey;
  connectSessionToken;
  debug = false;
  width = null;
  height = null;
  tm = null;
  win = null;
  constructor(config = {}) {
    config.host = config.host || prodHost; // Default to Nango Cloud.
    config.websocketsPath = config.websocketsPath || '/'; // Default to root path.
    this.debug = config.debug || false;
    if (this.debug) {
      console.log(debugLogPrefix, `Debug mode is enabled.`);
      console.log(debugLogPrefix, `Using host: ${config.host}.`);
    }
    if (config.width) {
      this.width = config.width;
    }
    if (config.height) {
      this.height = config.height;
    }
    this.hostBaseUrl = config.host.replace(/\/+$/, ''); // Remove trailing slash.
    this.status = AuthorizationStatus.IDLE;
    this.publicKey = config.publicKey;
    this.connectSessionToken = config.connectSessionToken;
    try {
      const baseUrl = new URL(this.hostBaseUrl);
      // Build the websockets url based on the host url.
      // The websockets path is considered relative to the baseUrl, and with the protocol updated
      const websocketUrl = new URL(config.websocketsPath, baseUrl);
      this.websocketsBaseUrl = websocketUrl.toString().replace('https://', 'wss://').replace('http://', 'ws://');
    } catch {
      throw new AuthError('Invalid URL provided for the Nango host.', 'invalidHostUrl');
    }
  }
  async create(providerConfigKey, connectionIdOrConnectionConfig, moreConnectionConfig) {
    this.ensureCredentials();
    let connectionId = null;
    let connectionConfig = moreConnectionConfig;
    if (typeof connectionIdOrConnectionConfig === 'string') {
      connectionId = connectionIdOrConnectionConfig;
    } else {
      connectionConfig = connectionIdOrConnectionConfig;
    }
    const url = this.hostBaseUrl + `/auth/unauthenticated/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`;
    const res = await this.triggerAuth({
      authUrl: url
    });
    return res;
  }
  auth(providerConfigKey, connectionIdOrOptions, moreOptions) {
    this.ensureCredentials();
    let connectionId = null;
    let options = moreOptions;
    if (typeof connectionIdOrOptions === 'string') {
      connectionId = connectionIdOrOptions;
    } else {
      options = {
        ...options,
        ...connectionIdOrOptions
      };
    }
    if (options && 'credentials' in options && ('token_id' in options.credentials && 'token_secret' in options.credentials || !('oauth_client_id_override' in options.credentials) || !('oauth_client_secret_override' in options.credentials)) && Object.keys(options.credentials).length > 0) {
      const credentials = options.credentials;
      const {
        credentials: _,
        ...connectionConfig
      } = options;
      return this.customAuth(providerConfigKey, connectionId, this.convertCredentialsToConfig(credentials), connectionConfig);
    }
    const url = this.hostBaseUrl + `/oauth/connect/${providerConfigKey}${this.toQueryString(connectionId, options)}`;
    try {
      new URL(url);
    } catch {
      throw new AuthError('Invalid URL provided for the Nango host.', 'invalidHostUrl');
    }
    return new Promise((resolve, reject) => {
      // Clear state if the modal is closed
      if (this.win?.modal?.closed) {
        this.clear();
      }
      const successHandler = (providerConfigKey, connectionId, isPending = false) => {
        if (this.status !== AuthorizationStatus.BUSY) {
          return;
        }
        this.status = AuthorizationStatus.DONE;
        resolve({
          providerConfigKey: providerConfigKey,
          connectionId: connectionId,
          isPending
        });
        return;
      };
      const errorHandler = (errorType, errorDesc) => {
        if (this.status !== AuthorizationStatus.BUSY) {
          return;
        }
        this.status = AuthorizationStatus.DONE;
        const error = new AuthError(errorDesc, errorType);
        reject(error);
        return;
      };
      if (this.status === AuthorizationStatus.BUSY) {
        const error = new AuthError('The authorization window is already opened', 'windowIsOpened');
        reject(error);
        return;
      }
      // Save authorization status (for handler)
      this.status = AuthorizationStatus.BUSY;
      // Open authorization modal
      this.win = new AuthorizationModal(this.websocketsBaseUrl, url, successHandler, errorHandler, {
        width: this.width,
        height: this.height
      }, this.debug);
      if (options?.detectClosedAuthWindow) {
        this.tm = setInterval(() => {
          if (!this.win || !this.win.modal) {
            return;
          }
          if (this.win.modal.window && !this.win.modal.window.closed) {
            return;
          }
          if (this.win.isProcessingMessage) {
            // Modal is still processing a web socket message from the server
            // We ignore the window being closed for now
            return;
          }
          clearInterval(this.tm);
          this.win = null;
          this.status = AuthorizationStatus.CANCELED;
          const error = new AuthError('The authorization window was closed before the authorization flow was completed', 'windowClosed');
          reject(error);
        }, 500);
      }
    });
  }
  reconnect(providerConfigKey, options) {
    if (!this.connectSessionToken) {
      throw new AuthError('Reconnect requires a session token', 'missing_connect_session_token');
    }
    return this.auth(providerConfigKey, options);
  }
  /**
   * Clear state of the frontend SDK
   */
  clear() {
    if (this.tm) {
      clearInterval(this.tm);
    }
    if (this.win) {
      try {
        this.win.close();
      } catch {
        // do nothing
      }
      this.win = null;
    }
    this.status = AuthorizationStatus.IDLE;
  }
  /**
   * Open managed Connect UI
   */
  openConnectUI(params) {
    const connect = new ConnectUI({
      sessionToken: this.connectSessionToken,
      ...params
    });
    connect.open();
    return connect;
  }
  /**
   * Converts the provided credentials to a Connection configuration object
   * @param credentials - The credentials to convert
   * @returns The connection configuration object
   */
  convertCredentialsToConfig(credentials) {
    const params = {};
    if ('type' in credentials && 'username' in credentials && 'password' in credentials && credentials.type === 'SIGNATURE') {
      const signatureCredentials = {
        type: credentials.type,
        username: credentials.username,
        password: credentials.password
      };
      return {
        params: signatureCredentials
      };
    }
    if ('username' in credentials) {
      params['username'] = credentials.username || '';
    }
    if ('password' in credentials) {
      params['password'] = credentials.password || '';
    }
    if ('apiKey' in credentials) {
      params['apiKey'] = credentials.apiKey || '';
    }
    if ('privateKeyId' in credentials || 'issuerId' in credentials || 'privateKey' in credentials) {
      const jwtParams = {};
      if (credentials.privateKeyId) {
        jwtParams['privateKeyId'] = credentials.privateKeyId;
      }
      if (credentials.issuerId) {
        jwtParams['issuerId'] = credentials.issuerId;
      }
      if (credentials.privateKey) {
        if (typeof credentials.privateKey === 'string') {
          jwtParams['privateKey'] = credentials.privateKey;
        } else if (typeof credentials.privateKey === 'object' && 'id' in credentials.privateKey && 'secret' in credentials.privateKey) {
          jwtParams['privateKey'] = credentials.privateKey;
        }
      }
      return {
        params: jwtParams
      };
    }
    if ('privateKeyId' in credentials && 'issuerId' in credentials && 'privateKey' in credentials) {
      const appStoreCredentials = {
        params: {
          privateKeyId: credentials.privateKeyId,
          issuerId: credentials.issuerId,
          privateKey: credentials.privateKey
        }
      };
      if ('scope' in credentials && (typeof credentials.scope === 'string' || Array.isArray(credentials.scope))) {
        appStoreCredentials.params['scope'] = credentials.scope;
      }
      return appStoreCredentials;
    }
    if ('client_id' in credentials && 'client_secret' in credentials) {
      const oauth2CCCredentials = {
        client_id: credentials.client_id,
        client_secret: credentials.client_secret
      };
      return {
        params: oauth2CCCredentials
      };
    }
    if ('token_id' in credentials && 'token_secret' in credentials) {
      const tbaCredentials = {
        token_id: credentials.token_id,
        token_secret: credentials.token_secret
      };
      if ('oauth_client_id_override' in credentials) {
        tbaCredentials['oauth_client_id_override'] = credentials.oauth_client_id_override;
      }
      if ('oauth_client_secret_override' in credentials) {
        tbaCredentials['oauth_client_secret_override'] = credentials.oauth_client_secret_override;
      }
      return {
        params: tbaCredentials
      };
    }
    if ('pat_name' in credentials && 'pat_secret' in credentials) {
      const tableauCredentials = {
        pat_name: credentials.pat_name,
        pat_secret: credentials.pat_secret
      };
      if ('content_url' in credentials) {
        tableauCredentials['content_url'] = credentials.content_url;
      }
      return {
        params: tableauCredentials
      };
    }
    if ('username' in credentials && 'password' in credentials && 'organization_id' in credentials && 'dev_key' in credentials) {
      const BillCredentials = {
        username: credentials.username,
        password: credentials.password,
        organization_id: credentials.organization_id,
        dev_key: credentials.dev_key
      };
      return {
        params: BillCredentials
      };
    }
    if ('type' in credentials && credentials.type === 'TWO_STEP') {
      const twoStepCredentials = {
        ...credentials
      };
      return {
        params: twoStepCredentials
      };
    }
    return {
      params
    };
  }
  async triggerAuth({
    authUrl,
    credentials
  }) {
    const res = await fetch(authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...(credentials ? {
        body: JSON.stringify(credentials)
      } : {})
    });
    if (!res.ok) {
      const errorResponse = await res.json();
      throw new AuthError(errorResponse.error.message, errorResponse.error.code);
    }
    return res.json();
  }
  /**
   * Performs authorization based on the provided credentials i.e api, basic, appstore and oauth2
   * @param providerConfigKey - The key identifying the provider configuration on Nango
   * @param connectionId - The ID of the connection for which to create the custom Authorization
   * @param connectionConfigWithCredentials - The connection configuration containing the credentials
   * @param connectionConfig - Optional. Additional connection configuration
   * @returns A promise that resolves with the authorization result
   */
  async customAuth(providerConfigKey, connectionId, connectionConfigWithCredentials, connectionConfig) {
    const {
      params: credentials
    } = connectionConfigWithCredentials;
    if (!credentials) {
      throw new AuthError('You must specify credentials.', 'missingCredentials');
    }
    if ('type' in credentials && credentials['type'] === 'TWO_STEP') {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/auth/two-step/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('type' in credentials && credentials['type'] === 'SIGNATURE' && 'username' in credentials && 'password' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/auth/signature/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('username' in credentials && 'password' in credentials && 'organization_id' in credentials && 'dev_key' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/auth/bill/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('apiKey' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/api-auth/api-key/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('username' in credentials || 'password' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/api-auth/basic/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('privateKeyId' in credentials || 'issuerId' in credentials || 'privateKey' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/auth/jwt/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('privateKeyId' in credentials && 'issuerId' in credentials && 'privateKey' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/app-store-auth/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('token_id' in credentials && 'token_secret' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/auth/tba/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('pat_name' in credentials && 'pat_secret' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/auth/tableau/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    if ('client_id' in credentials && 'client_secret' in credentials) {
      return await this.triggerAuth({
        authUrl: this.hostBaseUrl + `/oauth2/auth/${providerConfigKey}${this.toQueryString(connectionId, connectionConfig)}`,
        credentials: credentials
      });
    }
    return Promise.reject(new Error('Something went wrong with the authorization'));
  }
  /**
   * Converts the connection ID and configuration parameters into a query string
   * @param connectionId - The ID of the connection for which to generate a query string
   * @param connectionConfig - Optional. Additional configuration for the connection
   * @returns The generated query string
   */
  toQueryString(connectionId, connectionConfig) {
    const query = [];
    if (connectionId) {
      query.push(`connection_id=${connectionId}`);
    }
    if (this.publicKey) {
      query.push(`public_key=${this.publicKey}`);
    }
    if (this.connectSessionToken) {
      query.push(`connect_session_token=${this.connectSessionToken}`);
    }
    if (connectionConfig) {
      for (const param in connectionConfig.params) {
        const val = connectionConfig.params[param];
        if (typeof val === 'string') {
          query.push(`params[${param}]=${val}`);
        }
      }
      if (connectionConfig.hmac) {
        query.push(`hmac=${connectionConfig.hmac}`);
      }
      if (connectionConfig.user_scope) {
        query.push(`user_scope=${connectionConfig.user_scope.join(',')}`);
      }
      if (connectionConfig.credentials) {
        const credentials = connectionConfig.credentials;
        if ('oauth_client_id_override' in credentials) {
          query.push(`credentials[oauth_client_id_override]=${encodeURIComponent(credentials.oauth_client_id_override)}`);
        }
        if ('oauth_client_secret_override' in credentials) {
          query.push(`credentials[oauth_client_secret_override]=${encodeURIComponent(credentials.oauth_client_secret_override)}`);
        }
        if ('token_id' in credentials) {
          query.push(`token_id=${encodeURIComponent(credentials.token_id)}`);
        }
        if ('token_secret' in credentials) {
          query.push(`token_secret=${encodeURIComponent(credentials.token_secret)}`);
        }
      }
      for (const param in connectionConfig.authorization_params) {
        const val = connectionConfig.authorization_params[param];
        if (typeof val === 'string') {
          query.push(`authorization_params[${param}]=${val}`);
        } else if (val === undefined) {
          query.push(`authorization_params[${param}]=undefined`);
        }
      }
    }
    return query.length === 0 ? '' : '?' + query.join('&');
  }
  /**
   * Check that we have one valid credential
   * It's not done in the constructor because if you only use Nango Connect it's not relevant to throw an error
   */
  ensureCredentials() {
    if (!this.publicKey && !this.connectSessionToken) {
      throw new AuthError('You must specify a public key OR a connect session token (cf. documentation).', 'missingAuthToken');
    }
  }
}
/**
 * AuthorizationModal class
 */
export { Nango as default };
class AuthorizationModal {
  url;
  features;
  width = 500;
  height = 600;
  swClient;
  debug;
  wsClientId;
  errorHandler;
  modal;
  isProcessingMessage = false;
  constructor(webSocketUrl, url, successHandler, errorHandler, {
    width,
    height
  }, debug) {
    // Window modal URL
    this.url = url;
    this.debug = debug || false;
    const {
      left,
      top,
      computedWidth,
      computedHeight
    } = this.layout(width || this.width, height || this.height);
    // Window modal features
    this.features = {
      width: computedWidth,
      height: computedHeight,
      top,
      left,
      scrollbars: 'yes',
      resizable: 'yes',
      status: 'no',
      toolbar: 'no',
      location: 'no',
      copyhistory: 'no',
      menubar: 'no',
      directories: 'no'
    };
    this.swClient = new WebSocket(webSocketUrl);
    this.errorHandler = errorHandler;
    this.swClient.onmessage = message => {
      this.isProcessingMessage = true;
      this.handleMessage(message, successHandler);
      this.isProcessingMessage = false;
    };
  }
  /**
   * Handles the messages received from the Nango server via WebSocket
   * @param message - The message event containing data from the server
   * @param successHandler - The success handler function to be called when a success message is received
   */
  handleMessage(message, successHandler) {
    const data = JSON.parse(message.data);
    switch (data.message_type) {
      case "connection_ack" /* WSMessageType.ConnectionAck */:
        {
          if (this.debug) {
            console.log(debugLogPrefix, 'Connection ack received. Opening modal...');
          }
          this.wsClientId = data.ws_client_id;
          this.open();
          break;
        }
      case "error" /* WSMessageType.Error */:
        if (this.debug) {
          console.log(debugLogPrefix, 'Error received. Rejecting authorization...');
        }
        this.errorHandler(data.error_type, data.error_desc);
        this.swClient.close();
        break;
      case "success" /* WSMessageType.Success */:
        if (this.debug) {
          console.log(debugLogPrefix, 'Success received. Resolving authorization...');
        }
        successHandler(data.provider_config_key, data.connection_id);
        this.swClient.close();
        break;
      default:
        if (this.debug) {
          console.log(debugLogPrefix, 'Unknown message type received from Nango server. Ignoring...');
        }
        return;
    }
  }
  /**
   * Calculates the layout dimensions for a modal window based on the expected width and height
   * @param expectedWidth - The expected width of the modal window
   * @param expectedHeight - The expected height of the modal window
   * @returns The layout details including left and top positions, as well as computed width and height
   */
  layout(expectedWidth, expectedHeight) {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = screenWidth / 2 - expectedWidth / 2;
    const top = screenHeight / 2 - expectedHeight / 2;
    const computedWidth = Math.min(expectedWidth, screenWidth);
    const computedHeight = Math.min(expectedHeight, screenHeight);
    return {
      left: Math.max(left, 0),
      top: Math.max(top, 0),
      computedWidth,
      computedHeight
    };
  }
  /**
   * Opens a modal window with the specified WebSocket client ID
   */
  open() {
    if (!this.wsClientId) {
      this.errorHandler('missing_ws_client_id', 'Missing WS Client ID while opening modal');
      return;
    }
    const popup = window.open(this.url + '&ws_client_id=' + this.wsClientId, '_blank', this.featuresToString());
    if (!popup || popup.closed || typeof popup.closed == 'undefined') {
      this.errorHandler('blocked_by_browser', 'Modal blocked by browser');
      return;
    }
    this.modal = popup;
  }
  /**
   * Converts the features object of this class to a string
   * @returns The string representation of features
   */
  featuresToString() {
    const features = this.features;
    const featuresAsString = [];
    for (const key in features) {
      featuresAsString.push(key + '=' + features[key]);
    }
    return featuresAsString.join(',');
  }
  /**
   * Close modal, if opened
   */
  close() {
    if (this.modal && !this.modal.closed) {
      this.modal.close();
    }
  }
}
